<template>
  <select :name="name">
    <option v-for="(timezone, index) in timezones" :key="index" :value="timezone.shortStr">[GMT{{ timezone.offset }}] {{ timezone.shortStr }}<span v-if="timezone.longStr != ''">: </span>{{ timezone.longStr }}</option>
  </select>
</template>

<script>

  export default
  {
    props: ['localtime', 'name'],
    data() {
      return {
        timezones: [
          {longStr: "", offset: "", shortStr: ""},            // user's timezone
          {longStr: "International Date Line West", offset: "", shortStr: "Pacific/Wake"},
          {longStr: "Midway Island", offset: "", shortStr: "Pacific/Apia"},
          {longStr: "Samoa", offset: "", shortStr: "Pacific/Apia"},
          {longStr: "Hawaii", offset: "", shortStr: "Pacific/Honolulu"},
          {longStr: "Alaska", offset: "", shortStr: "America/Anchorage"},
          {longStr: "Pacific Time (US &amp; Canada); Tijuana", offset: "", shortStr: "America/Los_Angeles"},
          {longStr: "Arizona", offset: "", shortStr: "America/Phoenix"},
          {longStr: "Chihuahua", offset: "", shortStr: "America/Chihuahua"},
          {longStr: "La Paz", offset: "", shortStr: "America/Chihuahua"},
          {longStr: "Mazatlan", offset: "", shortStr: "America/Chihuahua"},
          {longStr: "Mountain Time (US &amp; Canada)", offset: "", shortStr: "America/Denver"},
          {longStr: "Central America", offset: "", shortStr: "America/Managua"},
          {longStr: "Central Time (US &amp; Canada)", offset: "", shortStr: "America/Chicago"},
          {longStr: "Guadalajara", offset: "", shortStr: "America/Mexico_City"},
          {longStr: "Mexico City", offset: "", shortStr: "America/Mexico_City"},
          {longStr: "Monterrey", offset: "", shortStr: "America/Mexico_City"},
          {longStr: "Saskatchewan", offset: "", shortStr: "America/Regina"},
          {longStr: "Bogota", offset: "", shortStr: "America/Bogota"},
          {longStr: "Eastern Time (US &amp; Canada)", offset: "", shortStr: "America/New_York"},
          {longStr: "Indiana (East)", offset: "", shortStr: "America/Indiana/Indianapolis"},
          {longStr: "Lima", offset: "", shortStr: "America/Bogota"},
          {longStr: "Quito", offset: "", shortStr: "America/Bogota"},
          {longStr: "Atlantic Time (Canada)", offset: "", shortStr: "America/Halifax"},
          {longStr: "Caracas", offset: "", shortStr: "America/Caracas"},
          {longStr: "La Paz", offset: "", shortStr: "America/Caracas"},
          {longStr: "Santiago", offset: "", shortStr: "America/Santiago"},
          {longStr: "Newfoundland", offset: "", shortStr: "America/St_Johns"},
          {longStr: "Brasilia", offset: "", shortStr: "America/Sao_Paulo"},
          {longStr: "Buenos Aires", offset: "", shortStr: "America/Argentina/Buenos_Aires"},
          {longStr: "Georgetown", offset: "", shortStr: "America/Argentina/Buenos_Aires"},
          {longStr: "Greenland", offset: "", shortStr: "America/Godthab"},
          {longStr: "Mid-Atlantic", offset: "", shortStr: "America/Noronha"},
          {longStr: "Azores", offset: "", shortStr: "Atlantic/Azores"},
          {longStr: "Cape Verde Is.", offset: "", shortStr: "Atlantic/Cape_Verde"},
          {longStr: "Casablanca", offset: "", shortStr: "Africa/Casablanca"},
          {longStr: "Edinburgh", offset: "", shortStr: "Europe/London"},
          {longStr: "Greenwich Mean Time : Dublin", offset: "", shortStr: "Europe/London"},
          {longStr: "Lisbon", offset: "", shortStr: "Europe/London"},
          {longStr: "London", offset: "", shortStr: "Europe/London"},
          {longStr: "Monrovia", offset: "", shortStr: "Africa/Casablanca"},
          {longStr: "Amsterdam", offset: "", shortStr: "Europe/Berlin"},
          {longStr: "Belgrade", offset: "", shortStr: "Europe/Belgrade"},
          {longStr: "Berlin", offset: "", shortStr: "Europe/Berlin"},
          {longStr: "Bern", offset: "", shortStr: "Europe/Berlin"},
          {longStr: "Bratislava", offset: "", shortStr: "Europe/Belgrade"},
          {longStr: "Brussels", offset: "", shortStr: "Europe/Paris"},
          {longStr: "Budapest", offset: "", shortStr: "Europe/Belgrade"},
          {longStr: "Copenhagen", offset: "", shortStr: "Europe/Paris"},
          {longStr: "Ljubljana", offset: "", shortStr: "Europe/Belgrade"},
          {longStr: "Madrid", offset: "", shortStr: "Europe/Paris"},
          {longStr: "Paris", offset: "", shortStr: "Europe/Paris"},
          {longStr: "Prague", offset: "", shortStr: "Europe/Belgrade"},
          {longStr: "Rome", offset: "", shortStr: "Europe/Berlin"},
          {longStr: "Sarajevo", offset: "", shortStr: "Europe/Sarajevo"},
          {longStr: "Skopje", offset: "", shortStr: "Europe/Sarajevo"},
          {longStr: "Stockholm", offset: "", shortStr: "Europe/Berlin"},
          {longStr: "Vienna", offset: "", shortStr: "Europe/Berlin"},
          {longStr: "Warsaw", offset: "", shortStr: "Europe/Sarajevo"},
          {longStr: "West Central Africa", offset: "", shortStr: "Africa/Lagos"},
          {longStr: "Zagreb", offset: "", shortStr: "Europe/Sarajevo"},
          {longStr: "Athens", offset: "", shortStr: "Europe/Istanbul"},
          {longStr: "Bucharest", offset: "", shortStr: "Europe/Bucharest"},
          {longStr: "Cairo", offset: "", shortStr: "Africa/Cairo"},
          {longStr: "Harare", offset: "", shortStr: "Africa/Johannesburg"},
          {longStr: "Helsinki", offset: "", shortStr: "Europe/Helsinki"},
          {longStr: "Istanbul", offset: "", shortStr: "Europe/Istanbul"},
          {longStr: "Jerusalem", offset: "", shortStr: "Asia/Jerusalem"},
          {longStr: "Kyiv", offset: "", shortStr: "Europe/Helsinki"},
          {longStr: "Minsk", offset: "", shortStr: "Europe/Istanbul"},
          {longStr: "Pretoria", offset: "", shortStr: "Africa/Johannesburg"},
          {longStr: "Riga", offset: "", shortStr: "Europe/Helsinki"},
          {longStr: "Sofia", offset: "", shortStr: "Europe/Helsinki"},
          {longStr: "Tallinn", offset: "", shortStr: "Europe/Helsinki"},
          {longStr: "Vilnius", offset: "", shortStr: "Europe/Helsinki"},
          {longStr: "Baghdad", offset: "", shortStr: "Asia/Baghdad"},
          {longStr: "Kuwait", offset: "", shortStr: "Asia/Riyadh"},
          {longStr: "Moscow", offset: "", shortStr: "Europe/Moscow"},
          {longStr: "Nairobi", offset: "", shortStr: "Africa/Nairobi"},
          {longStr: "Riyadh", offset: "", shortStr: "Asia/Riyadh"},
          {longStr: "St. Petersburg", offset: "", shortStr: "Europe/Moscow"},
          {longStr: "Volgograd", offset: "", shortStr: "Europe/Moscow"},
          {longStr: "Tehran", offset: "", shortStr: "Asia/Tehran"},
          {longStr: "Abu Dhabi", offset: "", shortStr: "Asia/Muscat"},
          {longStr: "Baku", offset: "", shortStr: "Asia/Tbilisi"},
          {longStr: "Muscat", offset: "", shortStr: "Asia/Muscat"},
          {longStr: "Tbilisi", offset: "", shortStr: "Asia/Tbilisi"},
          {longStr: "Yerevan", offset: "", shortStr: "Asia/Tbilisi"},
          {longStr: "Kabul", offset: "", shortStr: "Asia/Kabul"},
          {longStr: "Ekaterinburg", offset: "", shortStr: "Asia/Yekaterinburg"},
          {longStr: "Islamabad", offset: "", shortStr: "Asia/Karachi"},
          {longStr: "Karachi", offset: "", shortStr: "Asia/Karachi"},
          {longStr: "Tashkent", offset: "", shortStr: "Asia/Karachi"},
          {longStr: "Chennai", offset: "", shortStr: "Asia/Calcutta"},
          {longStr: "Kolkata", offset: "", shortStr: "Asia/Calcutta"},
          {longStr: "Mumbai", offset: "", shortStr: "Asia/Calcutta"},
          {longStr: "New Delhi", offset: "", shortStr: "Asia/Calcutta"},
          {longStr: "Kathmandu", offset: "", shortStr: "Asia/Katmandu"},
          {longStr: "Almaty", offset: "", shortStr: "Asia/Novosibirsk"},
          {longStr: "Astana", offset: "", shortStr: "Asia/Dhaka"},
          {longStr: "Dhaka", offset: "", shortStr: "Asia/Dhaka"},
          {longStr: "Novosibirsk", offset: "", shortStr: "Asia/Novosibirsk"},
          {longStr: "Sri Jayawardenepura", offset: "", shortStr: "Asia/Colombo"},
          {longStr: "Rangoon", offset: "", shortStr: "Asia/Rangoon"},
          {longStr: "Bangkok", offset: "", shortStr: "Asia/Bangkok"},
          {longStr: "Hanoi", offset: "", shortStr: "Asia/Bangkok"},
          {longStr: "Jakarta", offset: "", shortStr: "Asia/Bangkok"},
          {longStr: "Krasnoyarsk", offset: "", shortStr: "Asia/Krasnoyarsk"},
          {longStr: "Beijing", offset: "", shortStr: "Asia/Hong_Kong"},
          {longStr: "Chongqing", offset: "", shortStr: "Asia/Hong_Kong"},
          {longStr: "Hong Kong", offset: "", shortStr: "Asia/Hong_Kong"},
          {longStr: "Irkutsk", offset: "", shortStr: "Asia/Irkutsk"},
          {longStr: "Kuala Lumpur", offset: "", shortStr: "Asia/Singapore"},
          {longStr: "Perth", offset: "", shortStr: "Australia/Perth"},
          {longStr: "Singapore", offset: "", shortStr: "Asia/Singapore"},
          {longStr: "Taipei", offset: "", shortStr: "Asia/Taipei"},
          {longStr: "Ulaan Bataar", offset: "", shortStr: "Asia/Irkutsk"},
          {longStr: "Urumqi", offset: "", shortStr: "Asia/Hong_Kong"},
          {longStr: "Osaka", offset: "", shortStr: "Asia/Tokyo"},
          {longStr: "Sapporo", offset: "", shortStr: "Asia/Tokyo"},
          {longStr: "Seoul", offset: "", shortStr: "Asia/Seoul"},
          {longStr: "Tokyo", offset: "", shortStr: "Asia/Tokyo"},
          {longStr: "Yakutsk", offset: "", shortStr: "Asia/Yakutsk"},
          {longStr: "Adelaide", offset: "", shortStr: "Australia/Adelaide"},
          {longStr: "Darwin", offset: "", shortStr: "Australia/Darwin"},
          {longStr: "Brisbane", offset: "", shortStr: "Australia/Brisbane"},
          {longStr: "Canberra", offset: "", shortStr: "Australia/Sydney"},
          {longStr: "Guam", offset: "", shortStr: "Pacific/Guam"},
          {longStr: "Hobart", offset: "", shortStr: "Australia/Hobart"},
          {longStr: "Melbourne", offset: "", shortStr: "Australia/Sydney"},
          {longStr: "Port Moresby", offset: "", shortStr: "Pacific/Guam"},
          {longStr: "Sydney", offset: "", shortStr: "Australia/Sydney"},
          {longStr: "Vladivostok", offset: "", shortStr: "Asia/Vladivostok"},
          {longStr: "Magadan", offset: "", shortStr: "Asia/Magadan"},
          {longStr: "New Caledonia", offset: "", shortStr: "Asia/Magadan"},
          {longStr: "Solomon Is.", offset: "", shortStr: "Asia/Magadan"},
          {longStr: "Auckland", offset: "", shortStr: "Pacific/Auckland"},
          {longStr: "Fiji", offset: "", shortStr: "Pacific/Fiji"},
          {longStr: "Kamchatka", offset: "", shortStr: "Pacific/Fiji"},
          {longStr: "Marshall Is.", offset: "", shortStr: "Pacific/Fiji"},
          {longStr: "Wellington", offset: "", shortStr: "Pacific/Auckland"},
          {longStr: "Nuku\'alofa", offset: "", shortStr: "Pacific/Tongatapu"},
        ]
      }
    },
    mounted() {
      let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.timezones[0].shortStr = (userTimeZone === 'Etc/Unknown') ? 'Europe/Berlin' : userTimeZone;
      this.updateDst();
    },
    watch: {
      localtime: function() {
        this.updateDst();
      }
    },
    methods: {
      updateDst() {
        for (var i=0; i < this.timezones.length; i++) {
          let eventTime = this.$dayjs.tz(this.localtime, this.timezones[i].shortStr);
          let offsetMin = eventTime.utcOffset();
          let sign = Math.sign(offsetMin >= 0) ? '+' : '-';
          let hours = Math.floor(Math.abs(offsetMin)/60)
          let hoursStr = hours.toString();
          while (hoursStr.length < 2) hoursStr = "0" + hoursStr;
          let minutes = Math.abs(offsetMin)%60;
          let minutesStr = minutes.toString();
          while (minutesStr.length < 2) minutesStr = "0" + minutesStr;
          this.timezones[i].offset = sign + hoursStr + ':' + minutesStr;
        }
      }
    }
  }
</script>
