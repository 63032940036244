<template>
  <div class="w-full">
    <form class="stdEl bg-cardset bg-red-100 sm:p-8" action="/cardsets" method="POST" @submit="submitCallback">
      <input type="hidden" name="_token" :value="$page.props.csrf_token">

      <InputLabel for="event_type">
        {{ $t('eventType') }}
        <popover-button explanation='eventTypeExpl' />
      </InputLabel>
      <select @change="majorUpdate" id="event_type" name="event_type" v-model="form.event_type">
        <option value="private" v-html="$t('eventTypePrivate')" />
        <option value="public"  v-html="$t('eventTypePublic')" />
      </select>

      <InputLabel class="mt-4" for="address_option">
        {{ $t('postAddressOnCards') }}
        <popover-button explanation='postAddressOnCardsExpl' />
      </InputLabel>
      <select @change="formElementChange" id="address_option" name="address_option" v-model="form.address_option">
        <option                   value="chiffre" v-html="$t('addressOptionChiffre')" />
        <option                   value="own"     v-html="privateEv ? $t('addressOptionOwn') : $t('addressOptionHost')" />
        <option                   value="none"    v-html="$t('addressOptionNone')" />
        <option v-if="privateEv"  value="box"     v-html="$t('addressOptionBox')" />
      </select>

      <InputLabel class="mt-4" for="language">
        {{ $t('languageOnCards') }}
      </InputLabel>
      <select @change="majorUpdate" id="language" name="language" v-model="form.language">
        <option value="de" v-html="$t('german')"  />
        <option value="en" v-html="$t('english')" />
        <option value="fr" v-html="$t('french')" />
        <option value="es" v-html="$t('spanish')" />
      </select>

      <input type="hidden" name="mainfont" :value="fontData[fontIndSel]">
      <input v-if="direct_checkout" type="hidden" name="direct_checkout" :value="direct_checkout">

      <InputLabel class="mt-4">
        {{ $t('cardStyle') }}
      </InputLabel>
      <carousel-3d ref="carouselFront" @before-slide-change="onBeforeSlideChangeFront" @after-slide-change="onAfterSlideChangeFront" :width="carouselWidth" :height="carouselWidth*(426.0/600.0)" :startIndex="fontIndSel">
        <slide v-for="(slide, i) in fontData" :index="i" :key="i" class="shadow">
          <picture>
            <source :srcset="'/images/previews/' + form.language + '/' + fontData[i] + '/front.webp 600w, /images/previews/' + form.language + '/' + fontData[i] + '/front-400w.webp 400w'" type="image/webp">
            <source :srcset="'/images/previews/' + form.language + '/' + fontData[i] + '/front.jpg  600w, /images/previews/' + form.language + '/' + fontData[i] + '/front-400w.jpg  400w'" type="image/jpeg">
            <img :src="'/images/previews/' + form.language + '/' + fontData[i] + '/front.jpg'" :alt="fontData[i]">
          </picture>
        </slide>
      </carousel-3d>

      <InputLabel class="mt-4">
        {{ $t('labelStyle') }}
      </InputLabel>
      <carousel-3d ref="carouselLabel" @before-slide-change="onBeforeSlideChangeLabel" @after-slide-change="onAfterSlideChangeLabel" :width="carouselWidth*0.47297" :height="carouselWidth*0.47297*(154.0/300.0)" :startIndex="fontIndSel">
        <slide v-for="(slide, i) in fontData" :index="i" :key="i" class="shadow">
          <picture>
            <source :srcset="'/images/previews/' + form.language + '/' + fontData[i] + '/label.webp 300w, /images/previews/' + form.language + '/' + fontData[i] + '/label-200w.webp 200w'" type="image/webp">
            <source :srcset="'/images/previews/' + form.language + '/' + fontData[i] + '/label.jpg 300w, /images/previews/' + form.language + '/' + fontData[i] + '/label-200w.jpg 200w'" type="image/jpeg">
            <img :src="'/images/previews/' + form.language + '/' + fontData[i] + '/label.jpg'" :alt="fontData[i]">
          </picture>
        </slide>
      </carousel-3d>

      <div class="lg:flex justify-between mt-4">
        <div class="w-full mb-4 lg:w-1/3">
          <InputLabel for="event_name">
            {{ $t('eventname') }} <span class="font-normal text-sm">({{ $t('optional') }})</span>
            <popover-button explanation='eventnameExpl' />
          </InputLabel>
          <TextInput
            id="event_name"
            name="event_name"
            v-model="form.event_name"
            :placeholder="$t('eventNameDefault')"
            :onchange="formElementChange"
          />
        </div>
        <div class="w-full mb-4 lg:w-1/3">
          <InputLabel for="event_at">
            {{ $t('eventOn') }}
            <popover-button explanation='eventOnExpl' />
          </InputLabel>
          <TextInput
            id="event_at"
            name="event_at"
            v-model="form.event_at"
            type="date"
            placeholder="yyyy-mm-dd"
            :onchange="formElementChange"
            required
          />
        </div>
        <div class="w-full mb-4 lg:w-1/4">
          <InputLabel for="nof_copies">
            {{ $t('nofCards') }}
            <popover-button explanation='nofCardsExpl' />
          </InputLabel>
          <TextInput
            id="nof_copies"
            name="nof_copies"
            v-model="form.nof_copies"
            type="number"
            min=1 max=10000
            :onchange="nofCopiesChange"
          />
        </div>
      </div>

      <div class="w-full mb-4">
        <InputLabel for="event_timezone">
          {{ $t('timezoneOfEvent') }}
          <popover-button explanation='timezoneOfEventExpl' />
        </InputLabel>
        <timezone-select :localtime="timeforTimeZoneField" name="timezone" />
      </div>

      <div v-if="privateEv" class="mb-4">
        <InputLabel for="address_prefix_dropdown">
          {{ $t('titleAddress') }}
        </InputLabel>
        <select @change="majorUpdate" name="address_prefix_dropdown" v-model="form.address_prefix_ind">
          <option v-for="(prefix, index) in prefixes" :key="prefix.id" :value="index" v-html="prefix[form.language]" />
        </select>
        <TextInput
          v-if="prefixFree"
          class="mt-1"
          v-model="form.address_prefix_free"
          :onchange="majorUpdate"
          :placeholder="placeholder_free[form.language]"
          required
        />
        <input type="hidden" name="address_prefix"  :value="addressPrefix">
        <input type="hidden" name="occasion_string" :value="prefixes[form.address_prefix_ind]['occasion']">
      </div>
      <div v-else>
        <input type="hidden" name="occasion_string" value="undefined">
      </div>

      <div class="mb-4">
        <InputLabel v-if="privateEv" for="nameline">
          {{ $t('name_s') }}
          <popover-button explanation='name_sExpl' />
        </InputLabel>
        <InputLabel v-else for="nameline">
          {{ $t('nameHost') }}
          <popover-button explanation='nameHostExpl' />
        </InputLabel>
        <TextInput
          id="nameline"
          name="nameline"
          v-model="form.nameline"
          :onchange="formElementChange"
          :placeholder="publicEv ? $t('nameHostExample') : ((form.address_option === 'own') ? placeholderNameLong[form.language] : placeholderNameShort[form.language])"
          required
        />
      </div>

      <div v-if="(form.address_option === 'own')" class="flex justify-between mb-4">
        <div class="w-full">
          <InputLabel for="address">
            {{ $t('streetAndNumber') }}
            <popover-button explanation='addressExplanation' />
          </InputLabel>
          <TextInput
            id="address"
            name="address"
            v-model="form.address"
            :onchange="formElementChange"
            :placeholder="sampleAddress[form.language]"
            required
          />
        </div>
      </div>

      <div v-if="(form.address_option === 'own')" class="w-full mb-4">
        <InputLabel for="country_ISO">
          {{ $t('country') }}
          <popover-button explanation='addressExplanation' />
        </InputLabel>
        <select name="country_ISO" id="country_ISO" v-model="form.country_ISO">
          <option v-for="(value, key, idx) in $tm('countrySelectPreferred')" :key="'pref' + idx" :value="key" v-html="value" />
          <option disabled>──────────</option>
          <option v-for="(value, key, idx) in $tm('countrySelect')" :key="idx" :value="key" v-html="value" />
        </select>
      </div>

      <div v-if="(form.address_option === 'own')" class="sm:flex justify-between">
        <div class="w-full sm:w-1/4 mb-4">
          <InputLabel for="zip">
            {{ $t('zip') }}
            <popover-button explanation='addressExplanation' />
          </InputLabel>
          <TextInput
            id="zip"
            name="zip"
            v-model="form.zip"
            :onchange="formElementChange"
            placeholder="12345"
            required
          />
        </div>
        <div class="w-full sm:w-2/3 mb-4">
          <InputLabel for="city">
            {{ $t('city') }}
            <popover-button explanation='addressExplanation' />
          </InputLabel>
          <TextInput
            id="city"
            name="city"
            v-model="form.city"
            :onchange="formElementChange"
            :placeholder="sampleCity[form.language]"
            required
          />
        </div>
      </div>

      <div class="mb-4">
        <InputLabel for="greeting_prompt">
          {{ $t('greetingPrompt') }}
          <popover-button explanation='greetingPromptExpl' />
        </InputLabel>
        <TextInput
          id="greeting_prompt"
          name="greeting_prompt"
          v-model="form.greeting_prompt"
          :onchange="formElementChange"
          :placeholder="greetingPromptExample"
        />
      </div>

      <!-- Additional fields public event -->
      <div v-if="publicEv">

        <div class="flex my-1">
          <InputLabel class="flex items-center">
            <Checkbox
              @change="formElementChange"
              id="competition"
              name="competition"
              v-model="form.competition"
            />
            <span class="ml-2 mr-1" v-html="$t('competition')" />
            <popover-button explanation='competitionExpl' />
          </InputLabel>
        </div>

        <InputLabel class="mt-1">
          {{ $t('publicAdditionalFields') }}
        </InputLabel>
        <div class="flex my-1">
          <InputLabel class="flex items-center">
            <Checkbox
              @change="formElementChange"
              id="has_guest_address"
              name="has_guest_address"
              v-model="form.has_guest_address"
            />
            <span class="ml-2 mr-1" v-html="$t('postalAddress')" />
            <popover-button explanation='postalAddressExpl' />
          </InputLabel>
        </div>
        <div class="flex my-1">
          <InputLabel class="flex items-center">
            <Checkbox
              @change="formElementChange"
              id="has_guest_email"
              name="has_guest_email"
              v-model="form.has_guest_email"
            />
            <span class="ml-2 mr-1" v-html="$t('email')" />
            <popover-button explanation='emailExpl' />
          </InputLabel>
        </div>
        <div class="flex my-1">
          <InputLabel class="flex items-center">
            <Checkbox
              @change="formElementChange"
              id="has_guest_age"
              name="has_guest_age"
              v-model="form.has_guest_age"
            />
            <span class="ml-2 mr-1" v-html="$t('age')" />
          </InputLabel>
        </div>
        <div class="mt-2 mb-4">
          <InputLabel for="free_guest_field">
            {{ $t('individualField') }}
            <popover-button explanation='individualFieldExpl' />
          </InputLabel>
          <TextInput
            id="free_guest_field"
            name="free_guest_field"
            v-model="form.guest_field_individual"
            :onchange="formElementChange"
            :placeholder="sampleFieldIndividual[form.language]"
          />
        </div>

        <div v-if="form.competition" class="w-full mb-4">
          <InputLabel for="deadline">
            {{ $t('deadline') }}
            <popover-button explanation='deadlineExpl' />
          </InputLabel>
          <TextInput
            type="date"
            id="deadline"
            name="deadline"
            v-model="form.deadline"
            :onchange="formElementChange"
            placeholder="yyyy-mm-dd"
          />
        </div>
      </div>
      <!-- End: Additional fields public event -->

      <div v-if="(form.address_option === 'chiffre')" class="roundedBox bg-orange-200 text-sm">
        <input type="hidden" name="fee"               :value="$page.props.feeChiffre">
        <input type="hidden" name="cards_chargeable"  :value="$page.props.cardsChargeable">
        <i18n-t keypath="chiffreHint" tag="span">
          <template #feeSingleCard>
            <strong>{{ $filters.formatCurrency($page.props.feeChiffre) }}</strong>
          </template>
          <template #cardsChargeable>
            {{ $page.props.cardsChargeable }}
          </template>
        </i18n-t>
      </div>

      <InputLabel class="mt-4">
        <div class="flex items-center font-normal mb-4">
          <Checkbox @change="formElementChange" id="terms" name="terms" v-model="form.terms" required />
          <div class="ml-2 text-base">
            <i18n-t keypath="agreeTermsCard" tag="span">
              <template #termsLink>
                <Link href="/terms">{{ $t('terms') }}</Link>
              </template>
              <template #gdprLink>
                <Link href="/gdpr">{{ $t('privacyPolicy') }}</Link>
              </template>
            </i18n-t>
          </div>
        </div>
      </InputLabel>

      <PrimaryButton>
        <font-awesome-icon :icon="custom_icon ? custom_icon : 'magic'" class="mr-2" />
          <span v-if="$page.props.auth.user" v-html="custom_button ? $t(custom_button) : $t('createCardset')" />
          <span v-else v-html="custom_button ? $t(custom_button) : $t('previewHint')" />
      </PrimaryButton>
    </form>
  </div>
</template>

<script>
  import PopoverButton from '@/Shared/PopoverButton.vue'
  import TimezoneSelect from '@/Shared/TimezoneSelect.vue'
  import PrimaryButton from '@/Components/PrimaryButton.vue'
  import { Link, router } from '@inertiajs/vue3';
  import { Carousel3d, Slide } from 'vue3-carousel-3d';
  import Checkbox from '@/Components/Checkbox.vue';
  import InputLabel from '@/Components/InputLabel.vue';
  import TextInput from '@/Components/TextInput.vue';

  import "vue3-carousel-3d/dist/index.css"

  export default
  {
    props: ['nof_copies_default', 'custom_icon', 'custom_button', 'direct_checkout'],
    created() {
      //if (Object.keys(this.$page.errors).length) {
      //  if (typeof gtag !== 'undefined') {
      //    gtag( 'event', 'error_input_backend');
      //  }
      //}
      this.majorUpdate(null);
    },
    mounted() {
      this.form.language = this.$i18n.locale;
      this.majorUpdate();
    },
    data() {
      return {
        timeforTimeZoneField: this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
        placeholderNameShort:
          {en: "Betty and John",                      de: "Laura und Marc",                     fr: "Aurélie et Ludovic",         es: "María y Pablo"},
        placeholderNameLong:
          {en: "Betty and John Q. Public",            de: "Laura und Marc Mustermann",          fr: "Aurélie et Ludovic Bernard", es: "María y Pablo Martinez"},
        sampleAddress:
          {en: "123 Sample Street",                   de: "Musterstraße 123",                   fr: "123 Rue d'exemple",          es: "Calle Ejemplo 7, 3º, 1A Izda"},
        sampleCity:
          {en: "Sample City",                         de: "Musterhausen",                       fr: "Ville d'exemple",            es: "Ciudad Ejemplo"},
        prefixes: [
          {en: "To the Bridal Couple",                de: "An das Brautpaar",                   fr: "Aux jeunes mariés",                es: "A los novios",                         occasion: "wedding"},
          {en: "To the Birthday Child",               de: "An das Geburtstagskind",             fr: "À la personne fêtée",              es: "Al/a la cumpleañero/a",                occasion: "birthday"},
          {en: "To the Young Family",                 de: "An die junge Familie",               fr: "À la jeune famille",               es: "A la joven familia",                   occasion: "baby"},
          {en: "To the Celebrant",                    de: "An den Jubilar",                     fr: "Au jubilaire",                     es: "Al Celebrante",                        occasion: "undefined"},
          {en: "To the Celebrator",                   de: "An die Jubilarin",                   fr: "À la jubilaire",                   es: "A la Celebrante",                      occasion: "undefined"},
          {en: "To the Baptized",                     de: "An das Taufkind",                    fr: "À l'enfant baptisé",               es: "Al/ a la niño/a bautizado",            occasion: "undefined"},
          {en: "To the First Communicant",            de: "An das Kommunionkind",               fr: "Au communiant",                    es: "Al/a la niño/a de la comunión",        occasion: "undefined"},
          {en: "To the Confirmand",                   de: "An den Konfirmanden",                fr: "À la communiante",                 es: "Al Confirmando",                       occasion: "undefined"},
          {en: "To the Confirmee",                    de: "An die Konfirmandin",                fr: "Au confirmand",                    es: "A la Confirmanda",                     occasion: "undefined"},
          {en: "To the Catechumen",                   de: "An den Firmling",                    fr: "À la confirmande",                 es: "Al Catecúmeno",                        occasion: "undefined"},
          {en: "To the Coming-of-age Celebrant",      de: "An den Jugendweihling",              fr: "Aux jubilaires",                   es: "A los jubilados",                      occasion: "undefined"},
          {en: "[No particular title]",               de: "[Ohne spezielle Anrede]",            fr: "[Sans titre particulier]",         es: "[Ningún título en particular]",        occasion: "undefined"}, // last-1 must be "no particular title", then "prefixSet" is false
          {en: "[Your freely formulated title...]",   de: "[Deine frei formulierte Anrede...]", fr: "[Ton titre librement formulé...]", es: "[Tu título libremente formulado...]",  occasion: "undefined"}, // last one must be "other", then "prefixFree" is true
        ],
        placeholder_free:
          {en: 'To the School Freshman...?',          de: 'An den Schulanfänger...?',           fr: 'Au nouvel élève...?',              es: "Al/a la niño/a que empieza su etapa escolar"},
        greetingPromptPrivate:
          {en: "Message and wishes",                  de: "Nachricht und Wünsche",              fr: "Message et souhaits",              es: "Mensaje y deseos"},
        greetingPromptPublic:
          {en: "Message to the finder",               de: "Nachricht an den Finder",            fr: "Message au trouveur",              es: "Mensaje al descubridor"},
        sampleFieldIndividual:
          {en: "Team",                                de: "Team",                               fr: "Équipe",                           es: "Equipo"},
        form: {
          language: "de",
          event_name: "",
          greeting_prompt: "",
          free_guest_field: "",
          event_at: null,
          deadline: null,
          nof_copies: this.nof_copies_default ? this.nof_copies_default : 100,
          event_type: "private",
          address_option: "chiffre",
          address_prefix_ind: 0,
          address_prefix_free: null,
          nameline: null,
          address: null,
          zip: null,
          city: null,
          country_ISO: null,
          terms: 0,
          ad: 0,
          competition: 0,
          has_guest_address: 0,
          has_guest_email: 0,
          has_guest_age: 0,
        },
        fontData: [ 'cookie',
                    'amatic',
                    'arabella',
                    'coolvetica',
                    'greatvibes',
                    'montez',
                    'oswald',
                    'pristina',
                    'rochester',
                    'sacramento',
                    'adventpro',
                    'aladin',
                    'aramis',
                    'grandhotel',
                    'lobstertwo',
                    'satisfy'
        ],
        fontIndSel: 0,
        targetSlideIdxFront: null,
        targetSlideIdxLabel: null,
      }
    },
    components:
    {
      PopoverButton,
      TimezoneSelect,
      PrimaryButton,
      Link,
      Carousel3d,
      Slide,
      Checkbox,
      InputLabel,
      TextInput
    },
    methods:
    {
      onBeforeSlideChangeFront(index) {
        this.targetSlideIdxFront = index;
        if (this.targetSlideIdxLabel !== index) {
          this.targetSlideIdxLabel = index;
          this.$refs.carouselLabel.goSlide(index);
        }
        this.fontIndSel = index;
      },
      onBeforeSlideChangeLabel(index) {
        this.targetSlideIdxLabel = index;
        if (this.targetSlideIdxFront !== index) {
          this.targetSlideIdxFront = index;
          this.$refs.carouselFront.goSlide(index);
        }
        this.fontIndSel = index;
      },
      onAfterSlideChangeFront() {
        this.targetSlideIdxFront = null;
      },
      onAfterSlideChangeLabel() {
        this.targetSlideIdxLabel = null;
      },
      submitCallback: function() {
        // Google
        if (typeof gtag !== 'undefined') {
          gtag('event', 'conversion', {
            'send_to': 'AW-633552859/fomaCLyV2IcCENv_jK4C',
            'transaction_id': ''
          });
          gtag( 'event', 'create_cardset');
        }

        // Meta/Facebook
        if (typeof fbq !== 'undefined') {
          fbq('track', 'AddToCart');
        }

        // Microsoft/bing
        if (typeof window.uetq !== 'undefined') {
          window.uetq = window.uetq || [];
          window.uetq.push('event', 'create_cardset', {});
        }
      },

      formElementChange: function(e) {
        if (this.form.event_at) {
          this.timeforTimeZoneField = this.$dayjs(this.form.event_at + " 00:00:00", 'YYYY-MM-DD HH:mm:ss').add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
        }
        if (e && (typeof e.target !== 'undefined') && (typeof gtag !== 'undefined')) {
          gtag( 'event', 'changed_' + e.target.name);
        }
      },
      majorUpdate: function(e) {
        // should be called when major conditions change, e.g. language, kind of event (private/public), address prefix that justifies the deletion of some user data
        this.formElementChange(e);
        this.form.greeting_prompt = this.greetingPromptExample;
      },
      nofCopiesChange: function(e) {
        this.formElementChange(e);
        this.$emit('nofcopieschanged', this.form.nof_copies);
      }

      //submit()
      //{
      //  router.post('/cardsets', this.form);
      //},
    },
    computed: {
      carouselWidth: function() {

        var divWidth = window.innerWidth;

        if (divWidth < 400) {
          return 0.7 * divWidth;
        } else if (divWidth < 800) {
          return 0.6 * divWidth;
        } else if (divWidth < 1000) {
          return 0.4 * divWidth;
        } else {
          return 600.0;
        }
      },
      prefixFree: function() {
        // last option chosen, must be "[Your freely formulated title...]"
        return (this.form.address_prefix_ind === this.prefixes.length-1);
      },
      prefixSet: function() {
        // last-1 option chosen, must be "[No particular title]"
        return !(this.form.address_prefix_ind === this.prefixes.length-2);
      },
      privateEv: function() {
        return this.form.event_type == "private";
      },
      publicEv: function() {
        return this.form.event_type == "public";
      },
      addressPrefix: function() {
        if (this.prefixFree)
          return this.form.address_prefix_free;
        if (this.prefixSet)
          return this.prefixes[this.form.address_prefix_ind][this.form.language];
        return null;
      },
      greetingPromptExample: function() {
        let res;
        if (this.privateEv) {
          const lcPrefix = this.addressPrefix ? " " + this.addressPrefix.charAt(0).toLowerCase() + this.addressPrefix.slice(1) : "";
          res = this.greetingPromptPrivate[this.form.language] + lcPrefix;
        } else {
          res = this.greetingPromptPublic[this.form.language];
        }
        if (this.form.language == "fr")
          res += " ";
        return res + ":";
      }
    }
  }
</script>
